import React from 'react'
import * as PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'

const BreadcrumbsScript = ({ breadcrumbs }) => {
  return (
    <Helmet>
      <script type='application/ld+json'>
        {JSON.stringify({
          '@context': 'http://schema.org',
          '@type': 'BreadcrumbList',
          'itemListElement': breadcrumbs.map((obj, key) => {
            return {
              '@type': 'ListItem',
              'position': key + 1,
              'name': obj.schemaName || obj.name,
              'item': `https://www.hutsoninc.com${obj.link}`,
            }
          }),
        })}
      </script>
    </Helmet>
  )
}

BreadcrumbsScript.propTypes = {
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      link: PropTypes.string.isRequired,
      schemaName: PropTypes.string,
    })
  ).isRequired,
}

export default BreadcrumbsScript
