import React from 'react'
import * as PropTypes from 'prop-types'
import styled from 'styled-components'
import { GatsbyImage } from 'gatsby-plugin-image'

const HeroImage = ({ children, image, imageProps, ...props }) => {
  return (
    <Container {...props}>
      <ImageContainer>
        <Image image={image} {...imageProps} />
      </ImageContainer>
      {children && <Wrapper>{children}</Wrapper>}
    </Container>
  )
}

export const heroImageType = {
  children: PropTypes.node,
  imageProps: PropTypes.object.isRequired,
}

HeroImage.propTypes = heroImageType

HeroImage.defaultProps = {
  imageProps: {
    alt: '',
  },
}

const Container = styled.div`
  height: auto;
  position: relative;
  width: 100%;
`

const Wrapper = styled.div`
  height: 100%;
  position: relative;
  width: 100%;
  z-index: 1;
`

const ImageContainer = styled.div`
  height: 100%;
  position: absolute;
  width: 100%;
`

const Image = styled(GatsbyImage)`
  height: 100%;
  width: 100%;
`

export default HeroImage
