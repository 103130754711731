import React from 'react'
import * as PropTypes from 'prop-types'
import styled from 'styled-components'

const ContentFullWidth = ({ children, ...props }) => {
  return <StyledContentFullWidth {...props}>{children}</StyledContentFullWidth>
}

ContentFullWidth.propTypes = {
  children: PropTypes.node.isRequired,
}

const StyledContentFullWidth = styled.div`
  margin: auto;
  padding: ${props => props.theme.size.l} 0;
  width: 85%;

  @media ${props => props.theme.mediaQuery.largeUp} {
    padding: ${props => props.theme.size.xxl};
    width: 100%;
  }
`

export default ContentFullWidth
