import { string, node, object, arrayOf, shape } from 'prop-types'

export const BreadcrumbsType = {
  breadcrumbs: arrayOf(
    shape({
      name: string.isRequired,
      link: string.isRequired,
    })
  ),
}

export const HeroImageType = {
  image: object.isRequired,
  children: node,
}

export const HeroImageTitleType = {
  ...HeroImageType,
  ...BreadcrumbsType,
  children: node,
  title: string.isRequired,
}
