import React from 'react'
import * as PropTypes from 'prop-types'
import styled from 'styled-components'

const Section = ({ children, ...props }) => {
  return <StyledSection {...props}>{children}</StyledSection>
}

const StyledSection = styled.div`
  margin: ${props => props.theme.size.l} 0 0;

  @media ${props => props.theme.mediaQuery.mediumUp} {
    margin: ${props => props.theme.size.xxl} 0 0;
  }
`

Section.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Section
