import React from 'react'
import * as PropTypes from 'prop-types'
import styled from 'styled-components'
import HeroImage from './hero-image'
import Content from 'components/new/content'
import Breadcrumbs from 'components/hero-image/breadcrumbs'
import { BreadcrumbsType } from 'components/hero-image/types'

const HeroImageTitle = ({
  breadcrumbs,
  children,
  contentProps: incomingContentProps,
  overlayOpacity,
  title,
  ...props
}) => {
  const contentProps = {
    kind: 'full',
    ...incomingContentProps,
  }
  return (
    <HeroImage {...props}>
      <StyledContent {...contentProps}>
        <Title>{title}</Title>
        {breadcrumbs && <Breadcrumbs breadcrumbs={breadcrumbs} />}
        {children}
      </StyledContent>
      <Overlay overlayOpacity={overlayOpacity} />
    </HeroImage>
  )
}

HeroImageTitle.propTypes = {
  overlayOpacity: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  ...BreadcrumbsType,
}

HeroImageTitle.defaultProps = {
  overlayOpacity: 0.25,
}

const StyledContent = styled(Content)`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
`

const Title = styled.h1`
  color: #fff;
  font-size: 48px;
  margin: 0;
`

const Overlay = styled.div`
  background: ${props => `rgba(0, 0, 0, ${props.overlayOpacity})`};
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: -1;
`

export default HeroImageTitle
