import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { BreadcrumbsType } from './types'

const Breadcrumbs = ({ breadcrumbs, ...props }) => {
  return (
    <BreadcrumbsUl {...props}>
      {breadcrumbs.map((breadcrumb, key) => (
        <BreadcrumbsLi key={key}>
          {breadcrumb.link &&
            (key !== breadcrumbs.length - 1 ? (
              <Link to={breadcrumb.link}>{breadcrumb.name}</Link>
            ) : (
              <>{breadcrumb.name}</>
            ))}
        </BreadcrumbsLi>
      ))}
    </BreadcrumbsUl>
  )
}

Breadcrumbs.propTypes = BreadcrumbsType.isRequired

const BreadcrumbsUl = styled.ul`
  color: #fff;
  list-style-type: none;
  padding: 0;
  margin: 0;
  text-transform: capitalize;
  line-height: 1;
`

const BreadcrumbsLi = styled.li`
  display: inline-block;
  margin: 5px 5px 0 5px;

  :first-of-type {
    margin-left: 4px;
  }

  :after {
    display: inline-block;
    content: '>';
    padding-left: 8px;
  }

  :last-of-type:after {
    display: none;
  }

  a {
    color: inherit;
    text-decoration: none;

    :hover,
    :focus {
      text-decoration: underline;
    }
  }
`

export default Breadcrumbs
