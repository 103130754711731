import React from 'react'
import * as PropTypes from 'prop-types'
import styled from 'styled-components'
import { Link } from 'gatsby'
import Button from 'components/button/button'

const ProductTable = ({ items, ...props }) => {
  return (
    <Table {...props}>
      <thead>
        <tr>
          <td>Model</td>
          {items[0].tableData.map(spec => (
            <td key={spec.property}>{spec.property}</td>
          ))}
          {(items[0].slug || items[0].onClick) && <td />}
        </tr>
      </thead>
      <tbody>
        {items.map(prod => (
          <tr key={prod.name}>
            <th>
              {prod.slug && <Link to={prod.slug}>{prod.name}</Link>}
              {!prod.slug && prod.name}
            </th>
            {prod.tableData &&
              prod.tableData.map((spec, key) => {
                return (
                  <td key={key}>
                    <div className='inline-header'>{spec.property}</div>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: spec.data,
                      }}
                    />
                  </td>
                )
              })}
            {(prod.slug || prod.onClick) && (
              <td>
                {prod.slug && (
                  <Button className='cta-button' as={Link} to={prod.slug}>
                    {prod.buttonText || 'View Product'}
                  </Button>
                )}
                {prod.onClick && (
                  <Button className='cta-button' data-name={prod.name} onClick={prod.onClick}>
                    {prod.buttonText || 'Get a Quote'}
                  </Button>
                )}
              </td>
            )}
          </tr>
        ))}
      </tbody>
    </Table>
  )
}

const itemType = {
  name: PropTypes.string.isRequired,
  slug: PropTypes.string,
  buttonText: PropTypes.string,
  onClick: PropTypes.func,
  tableData: PropTypes.arrayOf(
    PropTypes.shape({
      property: PropTypes.string,
      data: PropTypes.string,
    })
  ).isRequired,
}

ProductTable.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape(itemType)).isRequired,
}

const Table = styled.table`
  background: #fff;
  border: none;
  border-collapse: collapse;
  margin: 0 auto;
  text-align: left;
  width: 100%;

  thead td {
    display: none;
  }

  tbody tr {
    border: 1px solid #d7d7d7;
    margin-bottom: 25px;
    font-size: 0.9em;
    display: block;

    :last-of-type {
      margin-bottom: 15px;
    }
  }

  tbody th {
    font-weight: bold;
    padding: 15px;
    background-color: #000;
    color: #fff;
    display: block;
    width: 100%;

    a {
      color: #fff;
    }
  }

  tbody td {
    padding: 10px 15px;
    display: block;
    width: 100%;

    div {
      display: inline-block;
      width: 50%;
      box-sizing: border-box;
      vertical-align: middle;
    }

    .inline-header {
      display: inline-block;
      font-weight: bold;
    }

    button {
      display: inline-block;
      float: none;
    }

    :nth-child(even) {
      background-color: #f7f7f7;
    }

    p {
      margin: 0;
      padding: 0;
    }

    a {
      text-decoration: none;
    }
  }

  .cta-button {
    white-space: nowrap;
  }

  @media (min-width: 900px) {
    border: 1px solid #d7d7d7;

    thead td {
      background-color: #000;
      color: #fff;
      padding: 20px 10px;
      font-weight: bold;
      font-size: 0.9em;
      display: table-cell;

      :first-of-type {
        padding-left: 25px;
      }

      :last-of-type {
        padding-right: 25px;
      }
    }

    tbody tr {
      border: none;
      font-size: 0.9em;
      display: table-row;

      :not(:last-of-type) {
        border-bottom: 1px solid #d7d7d7;
      }

      :nth-child(even) {
        background-color: #f7f7f7;

        th {
          background-color: #f7f7f7;
        }
      }

      :nth-child(odd) {
        background-color: #fff;

        th {
          background-color: #fff;
        }
      }
    }

    tbody th {
      font-weight: bold;
      padding: 10px 10px 10px 25px;
      color: #000;
      display: table-cell;
      width: auto;

      a {
        color: #377539;

        :hover,
        :focus {
          text-decoration: underline;
        }
      }
    }

    tbody td {
      display: table-cell;
      width: auto;
      padding: 10px;

      div {
        display: block;
        width: 100%;
      }

      .inline-header {
        display: none;
      }

      button {
        float: right;
      }

      :nth-child(even) {
        background-color: inherit;
      }

      :last-of-type {
        padding-right: 25px;
      }
    }
  }
`

export default ProductTable
