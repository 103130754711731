import React from 'react'
import styled from 'styled-components'

/**
 * @param {Object} props
 * @param {import('react').ReactNode} props.children
 * @param {('default' | 'full')} [props.kind]
 * @param {boolean} [props.xPadding] - Apply left and right padding
 * @param {boolean} [props.yPadding] - Apply top and bottom padding
 */
const Content = props => {
  const { children, kind = 'default', xPadding = true, yPadding = true, ...rest } = props
  return (
    <StyledContent kind={kind} xPadding={xPadding} yPadding={yPadding} {...rest}>
      {children}
    </StyledContent>
  )
}

const StyledContent = styled.div`
  margin: auto;
  padding: ${props => (props.yPadding ? `${props.theme.size.xl} 0` : 0)};
  position: relative;
  width: 85%;

  @media (min-width: 900px) {
    padding: ${props =>
      `${props.yPadding ? `${props.theme.size.xxl}` : 0} ${
        props.xPadding ? `${props.theme.size.xxl}` : 0
      }`};
    width: ${props => (props.kind === 'default' ? '75%' : '100%')};
  }
`

export default Content
